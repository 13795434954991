
<template>
  <div class="myTest content-bg">
<!--    //我的试卷-->
    <div class="tab-title">
      <div class="tabTitle-item active">试卷</div>
    </div>

    <div class="tab-item">
      <div class="list-header">
        <div class="query">
          <el-input
            v-model="keyword"
            size="small"
            placeholder="请输入试卷编号或者试卷名称"
            prefix-icon="el-icon-search"
          ></el-input>
          <el-button
            type="primary"
            size="small"
            @click="getTestPaperList(keyword)"
            >查询</el-button
          >
        </div>
      </div>

      <div class="table">
        <el-table :data="testPaperList" style="width: 100%" v-loading="loading">
          <el-table-column label="试卷编号" width="110">
            <template slot-scope="scope">E{{ scope.row.paperId }} </template>
          </el-table-column>
          <el-table-column label="试卷名称" min-width="220">
            <template slot-scope="scope">{{ scope.row.paperName }} </template>
          </el-table-column>
          <el-table-column prop="topicNum" label="题目数量" width="110">
          </el-table-column>
          <el-table-column prop="totalScore" label="总分" width="110">
          </el-table-column>
          <el-table-column prop="passMark" label="及格分数" width="110">
          </el-table-column>
          <el-table-column prop="enter_date" label="更新时间" width="240">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.createDate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                plain
                @click="releaseTest(scope.row.paperId, scope.row.paperName)"
                >导出试卷</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page">
        <el-pagination
          background
          layout="total, prev, pager, next,jumper"
          @current-change="currentChange"
          :total="total"
          :page-size="pageSize"
        />
      </div>

<!--      <li @click="password" >-->
<!--        <span class="iconfont" >&#xe606;</span>-->
<!--        <p>修改密码</p>-->
<!--      </li>-->

<!--      <el-button class="footer"   type="warning"  @click="logout">退出</el-button>-->

      <!-- 发布考试弹框 -->
<!--      <ReleaseTest ref="releaseTestDialog" />-->
    </div>
  </div>
</template>

<script>
import { color } from 'echarts';

import "@/assets/less/my_tab.less";
import ReleaseTest from '@/views/ReleaseTest'
var token = JSON.parse(localStorage.getItem("user")).token// 要保证取到
export default {
  name: "MyPapers",
  components: { ReleaseTest },
  data () {
    return {

      uploadAction: process.env.VUE_APP_BASE_URL + '/word/import',
      headersObj:{
        token:JSON.parse(window.localStorage.getItem('user')).token
      },
      loading: true,
      testPaperList: [],// 所有的试卷列表
      // 分页
      currentPage: 1,
      pageSize: 10,
      total: null,

      keyword: '',
    };
  },

  created () {
    this.getTestPaperList();
  },

  methods: {
    //获取试卷列表
    getTestPaperList (val = '') {
      console.log("key=" + val)
      this.loading = true
      let params = {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        keyword: val
      };
      console.log("params=" + JSON.stringify(params))
      setTimeout(() => {
        this.request.get("/paper/getTestPaperByU_id", { params }).then((res) => {
          this.testPaperList = res.data.records;
          console.log("list=" + JSON.stringify(this.testPaperList))
          this.total = parseInt(res.data.total);
          this.loading = false
        });
      }, 500);
    },

    //去创建新试卷
    createTestPaper () {
      const { href } = this.$router.resolve({
        name: "createPaper",
        params: { type: 'add' }
      });
      window.open(href, "_blank");
    },

    // 打印试卷
    releaseTest (paperId, paperName) {
      console.log("打印试卷id=" + paperId)
      const { href } = this.$router.resolve({
        name: "exportPaper",
        params: { tp_id:paperId,answer:1  }
      });
      window.open(href, "_blank");
      this.$refs.releaseTestDialog.releaseTest(paperId, paperName)
    },

    // 编辑试卷
    editTestPaper (tp_id) {
      console.log("更改试卷id=" + tp_id)
      const { href } = this.$router.resolve({
        name: "editPaper",
        params: { type: 'edit', tp_id }
      });
      window.open(href, "_blank");
    },

    //删除试卷
    deleteTest (tp_id) {
      console.log("删除试卷")

      //弹出删除弹窗

      this.$confirm('此操作将删除该条数据,是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 确定
          this.request.post("/paper/deleteTestPaper/" + tp_id).then((res) => {

            console.log(res)


          });
          this.getTestPaperList();
        })
        .catch(() => {
          // 取消
        })
    },

    //切换分页时触发
    currentChange (val) {
      this.currentPage = val;
      this.getTestPaperList();
    },

    //导入试卷
    uploadFile () {
      console.log("执行")
      this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))

    },
    //上传试卷
    fileChange () {
      // 上传文件
    },
    importWord (val = '') {

      // alert(params.keyword)
      this.request.get("/paper/getTestPaperByU_id2", { params }).then(res => {

        // testPaperList = res.data.content;
        this.testPaperList = res.data.content;
        alert(testPaperList)
        // total = parseInt(res.data.total);
        this.total = parseInt(res.data.total);
        alert(total)
      });
    },

    testBut2 (val = '') {
      let params = {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        keyword: val
      };
      this.request.post("/paper/getTestPaperByTp_id").then(res => {

        console.log("res=" + res)

      });
    },

    handleFileUploadSuccess (res) {
      this.$message.success("上传成功")
      this.load()
    },
    logout() {
      this.$router.push({
        name:"logout"
      })
      this.$router.push({path:"/home"})
      this.$message.success("退出成功")
    },
  }
};
</script>

<style lang="less" scoped>

.myTest {
  .tab-item {
    padding: 10px 20px;
  }
  .table {
    margin-top: 24px;
  }
  .releaseTest .el-dialog {
    .el-dialog__header {
      text-align: center;
      font-size: 16px;
    }
    .el-dialog__body {
      padding-top: 0px;
      padding-bottom: 0px;
      text-align: center;
    }
    .pagination {
      text-align: center;
    }
    .el-date-editor {
      margin: 38px 0px;
    };



  }
}
.footer {
  position: fixed;
  bottom: 40px;
  //width: 100%;
  margin-left:40%;
  line-height: el(--footer-height);
  //background: #42b983;
  //color: #fff;
}
.list-header {
  position: relative;
  //background: #fafafa;
  padding: 6px 24px;
  border-radius: 4px;
  height: 60px;
  box-sizing: border-box;
  //box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);

  span.list-title {
    display: inline-block;
    margin-right: 30px;
    font-size: 16px;
    line-height: 48px;
  }

  .create {
    background: #4788cc;
    color: #fff;
    border-color: #f6f6f6;
  }
  .query {
    position: absolute;
    right: 24px;
    top: 14px;
    width: 400px;
    height: 40px;
  }
  .query .el-input {
    width: 320px;
    margin-right: 20px;
  }
  .query .el-button {
    background: #4788cc;
    border-color: #4788cc;
    transition: 1s;
  }
}
</style>
