import { render, staticRenderFns } from "./PapersUser.vue?vue&type=template&id=23d90990&scoped=true"
import script from "./PapersUser.vue?vue&type=script&lang=js"
export * from "./PapersUser.vue?vue&type=script&lang=js"
import style0 from "./PapersUser.vue?vue&type=style&index=0&id=23d90990&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d90990",
  null
  
)

export default component.exports